<template>
  <div class="text-center">
    <div class="d-inline-flex align-items-center">
      <h1 style="color: white; text-shadow: -1px -1px 0 #6C757D, 1px -1px 0 #6C757D, -1px 1px 0 #6C757D, 1px 1px 0 #6C757D;">
        Parser</h1>
      <b-button class="m-lg-4" @click.prevent='updateAndGetAllSites()'>
        Update information
      </b-button>
    </div>
    <b-table :items="sites" :fields="fields" hover :bordered="true">
      <template #cell(checkPosts)="row">
        <b-button size="sm" class="mr-2" @click.prevent='check(row.item.eurlSite)'>
          Check
        </b-button>
      </template>
      <template #cell(parsePosts)="row">
        <b-button size="sm" class="mr-2" @click.prevent='parse(row.item.numberOfPosts)'>
          <template v-if="!isParsing">
            Parse
          </template>
          <template v-if="isParsing">
            <b-spinner small></b-spinner>
            Parsing...
          </template>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import adminService from "@/service/AdminService";

export default defineComponent({
  name: "ParserPage",
  data() {
    return {
      fields: [
        {
          key: 'id',
          sortable: true,
          label: 'ID'
        },
        {
          key: 'eurlSite',
          sortable: true,
          label: 'Site name'
        },
        {
          key: 'numberOfPosts',
          sortable: true,
          label: 'Number of posts'
        },
        {
          key: 'numberOfNewPosts',
          sortable: false,
          label: 'Number of new posts'
        },
        {
          key: 'checkPosts',
          sortable: false,
          label: 'Check for new posts'
        },
        {
          key: 'parsePosts',
          sortable: false,
          label: 'Parse new yachts'
        }
      ],
      sites: [],
      isParsing: false,

    }
  },
  methods: {
    async initScrapedWebsites() {
      await adminService.getAllScrapedWebsites()
          .then(response => {
            this.sites = response.data.list;
          })
          .catch(e => {
            console.log(e)
          })
    },
    async check(eurlSite) {
      alert(eurlSite);
    },
    async parse(numPosts) {
      this.isParsing = true;
      alert(numPosts);
      this.isParsing = false;
    },
    async updateAndGetAllSites() {
      await adminService.updateAndGetAllScrapedWebsites()
          .then(response => {
            this.sites = response.data.list;
            alert("COMPLETE !")
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
  mounted() {
    this.initScrapedWebsites();
  }
})
</script>

<style scoped>

</style>

<!--
   На вход нужно
    - массив
        - id
        - название сайта
        - кол-во спаршенных
        - кол-во добавленных за послдений парсинг
    - кол-во сайтов (которые можем парсить)
-->