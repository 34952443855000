<script setup>
import axios from "axios";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const yacht = ref({});

const loadCatalogYacht = async () => {
  try {
    const id = route.params.id;
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/by/${id}`);
    yacht.value = response.data.yacht;
  } catch (error) {
    console.error('Error loading yacht data:', error);
  }
};

const saveYachtChanges = async () => {
  try {
    const data = {
      catalog: yacht.value
    }
    await axios.post(`https://api.knotzone.com/api/v1/catalog/update`, data);
    await router.push('/catalog/yacht/' + yacht.value.id);
  } catch (error) {
    console.error('Failed to save changes:', error);
    alert('Error saving changes');
  }
};

onMounted(() => {
  loadCatalogYacht();
});
</script>

<template>
  <div class="max-w-4xl mx-auto p-5 bg-white rounded-lg shadow-lg text-center">
    <div class="text-center mb-6">
      <input v-model="yacht.name" class="text-2xl font-bold text-center w-full" />
      <input v-model="yacht.designer" class="text-sm text-gray-500 text-center w-full" placeholder="Designer" />
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div>
        <h2 class="text-lg font-semibold">Specifications</h2>
        <div
            v-for="spec in ['ballastType', 'ballastKg', 'ballastLb', 'beamFt', 'beamM', 'displacementKg', 'displacementLb', 'hullSpeed', 'laoft', 'laom', 'lwlft', 'lwlm', 'maxDraftFt', 'maxDraftM', 'riggingType', 'hullType', 'comfortRatio']"
            :key="spec"
            class="text-center">
          <span class="font-semibold">{{ spec }}:</span>
          <input v-model="yacht[spec]" :placeholder="spec" class="w-full text-center" />
        </div>
      </div>
      <div>
        <h2 class="text-lg font-semibold">Dimensions</h2>
        <div
            v-for="dim in ['ift', 'im', 'forestayLengthFT', 'forestayLengthM', 'jft', 'jm', 'pft', 'pm', 'eft', 'em', 'firstBuilt']"
            :key="dim"
            class="text-center">
          <span class="font-semibold">{{ dim }}:</span>
          <input v-model="yacht[dim]" :placeholder="dim" class="w-full text-center" />
        </div>
      </div>
    </div>
    <button style="color: black" @click.prevent="saveYachtChanges" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
      Save Changes
    </button>
  </div>
</template>

<style scoped>
input {
  border: 1px solid #ddd;
  padding: 8px;
  margin: 4px 0;
}
</style>