<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute} from "vue-router";
import MainMenu from "@/view/Menu.vue";

const catalog = ref([]);
const currentPage = ref(1);
const route = useRoute();
const totalPages = ref();

const loadCatalog = async () => {
  try {
    let page = route.params.page;
    if (!page) {
      page = 1;
    }
    currentPage.value = page;
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/all/page/${page}`);
    catalog.value = response.data.catalog;
    totalPages.value = response.data.numberOfPage;
  } catch (error) {
    console.error('Error loading catalog:', error);
  }
};

const uploadCurrencies = async () => {
  try {
    await axios.get(`https://api.knotzone.com/api/v1/currency/upload/new`);
  } catch (error) {
    console.error('Error loading catalog:', error);
  }
};

const recalculateCurrency = async () => {
  try {
    await axios.get(`https://api.knotzone.com/api/v1/currency/recalculate/new/yacht`);
  } catch (error) {
    console.error('Error loading catalog:', error);
  }
};

onMounted(() => {
  loadCatalog();
});
</script>

<template>
  <div class="row">
    <div class="col-3">
      <MainMenu />
    </div>

    <div class="col-8">
      <div class="mt-10 flex justify-center items-center">
        <div class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md">
          <div class="flex flex-col space-y-4">
            <button @click.prevent="uploadCurrencies()" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              Upload currencies
            </button>
            <button @click.prevent="recalculateCurrency()" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              Recalculate currency
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>

</style>