<template>
  <div class="sidebar-sticky menu">
    <ul class="nav flex-column">
      <li class="nav-item">
        <router-link to="/" class="nav-link active" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-home">
            <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          Parser
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/users" class="nav-link" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-users">
            <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          </svg>
          Users
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/" class="nav-link" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-file">
            <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z"></path>
            <polyline points="13 2 13 9 20 9"></polyline>
          </svg>
          Ad
          <div class="accordion" >
            <div id="accordion-1" visible accordion="my-accordion" role="tabpanel" style="margin-left: 15px">
              <li class="nav-item border text-center mb-2">
                <router-link to="/ad/merging" class="nav-link" href="#">
                  Merging ad
                </router-link>
              </li>

              <li class="nav-item border text-center mb-2">
                <router-link to="/ad/hiding" class="nav-link" href="#">
                  Hiding ad
                </router-link>
              </li>

              <li class="nav-item border text-center mb-2">
                <router-link to="/ad/groups" class="nav-link">
                  Ad's groups
                </router-link>
              </li>
              <li class="nav-item border text-center mb-2">
                <router-link to="/ad/remove/duplicates" class="nav-link">
                  Remove duplicates
                </router-link>
              </li>
            </div>
          </div>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/catalog" class="nav-link">
          <a class="nav-link" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-layers">
              <polygon points="12 2 2 7 12 12 22 7 12 2"></polygon>
              <polyline points="2 17 12 22 22 17"></polyline>
              <polyline points="2 12 12 17 22 12"></polyline>
            </svg>
            Catalog
          </a>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/currency" class="nav-link">
          <a class="nav-link" href="#">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-bar-chart-2">
              <line x1="18" y1="20" x2="18" y2="10"></line>
              <line x1="12" y1="20" x2="12" y2="4"></line>
              <line x1="6" y1="20" x2="6" y2="14"></line>
            </svg>
            Currency
          </a>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import collapse from "bootstrap/js/src/collapse";

export default defineComponent({
  name: "main-menu",
  computed: {
    collapse() {
      return collapse
    }
  }
})
</script>

<style scoped>
.menu {
  display: inline-block;
}
</style>