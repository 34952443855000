import axios from "axios";

export default axios.create({
    // prod :
    // baseURL: "https://api.knotzone.com/api/v1",
    // dev :
    baseURL: "https://api.knotzone.com/api/v1",
    headers: {
        'Content-Type': 'application/json'
    }
});
