<template>
  <div class="row feature">
    <div class="col-md-3">
      <MainMenu />
    </div>
    <div class="col-md-8">

      <b-card-group v-if="items.length">
        <div class="col-4 mb-2 post-width-for-phone" v-for="(yacht, index) in items" :key="index">
          <router-link :to="'/ad/hiding/' + yacht.id" class="router">
            <b-card tag="article" class="mb-2 select-card single-card">
              <template v-if="yacht.image">
                <b-card-img :src="getImage(yacht.image)" :alt="yacht.name" class="img-class" />
              </template>
              <template v-else>
                <b-card-img :src="getEmptyImg" :alt="yacht.name" class="img-class" />
              </template>
              <b-card-title>
                {{ yacht.make }} {{ yacht.name }} | {{ yacht.nominalLengthM }}m | {{
                  getCondition(yacht.isNew)
                }}
              </b-card-title>
              <b-card-text>
                {{ formatPrice(yacht.price) }} {{ yacht.currency }}
              </b-card-text>
              <b-card-footer>
                <div class="text-to-right">
                  <small v-if="yacht.createdDate != null" class="text-muted">
                    Create date: {{ getDatePattern(yacht.createdDate) }}
                  </small>
                  <br>
                  <small v-if="yacht.modifiedDate != null" class="text-muted">
                    Modified date: {{ getDatePattern(yacht.modifiedDate) }}
                  </small>
                  <br>
                  <small v-if="yacht.lastUpdateDate != null" class="text-muted">
                    Last check: {{ getTimeElapsed(yacht.lastUpdateDate) }}
                  </small>
                </div>
              </b-card-footer>
            </b-card>
          </router-link>
        </div>

        <b-pagination
            v-if="countOfPage > 1"
            v-model="currentPage"
            :total-rows="countOfPage"
            :per-page="1"
            align="center"
            @click="changePage"
            first-number
            last-number
        ></b-pagination>
      </b-card-group>

    </div>
    <div class="col-md-1" />
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import MainMenu from "@/view/Menu.vue";
import YachtService from "@/service/YachtService";

export default defineComponent({
  name: "MergingAd",
  components: {MainMenu},
  data() {
    return {
      items: [],
      currentPage: 1
    }
  },
  methods: {
    getImage(imageByte) {
      // Create Data URI
      return `data:image/jpg;base64,${imageByte}`;
    },
    // set date format
    getDatePattern(date) {
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      return `${year}.${month}.${day}`;
    },
    // set price format
    formatPrice(value) {
      if (value == null) return 'price is not specified';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    // calculate update time
    getTimeElapsed(date) {
      const currentTime = new Date();
      const timeDiffInSeconds = Math.floor((currentTime - new Date(date)) / 1000);

      if (timeDiffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (timeDiffInSeconds < 3600) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `${minutes} minutes ago`;
      } else if (timeDiffInSeconds < 3600 * 24) {
        const hours = Math.floor(timeDiffInSeconds / 3600);
        const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
        return `${hours} hours and ${minutes} minutes ago`;
      } else {
        const days = Math.floor(timeDiffInSeconds / (3600 * 24));
        if (days > 1) {
          return `${days} days ago`
        } else {
          return `${days} day ago`
        }
      }
    },
    // set condition
    getCondition(isNew) {
      if (isNew) {
        return 'NEW';
      } else {
        return 'USED';
      }
    },
    isNullOrEmpty(value) {
      return value === null || value === '';
    },
    getYachtsForPage() {
      YachtService.getAllByPageNumber('recentlyUpdatedDescending', this.currentPage)
          .then(response => {
            this.items = response.data;
            this.isEmpty = this.items.length === 0;
          })
          .catch(e => {
            console.log(e);
          });
    },
  },
  mounted() {
    this.getYachtsForPage();
  },
  computed: {
    getEmptyImg() {
      return require(`@/assets/img/img-missing.jpg`);
    }
  }
})
</script>

<style scoped>
.img-class {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

.router {
  text-decoration: none !important;
}

.router:hover {
  border: 2px rgb(0, 0, 0) solid;
}

.select-card:hover {
  border: 1px solid black;
}

.single-card {
  width: 100%;
  height: 100%;
  background: #F8F9FA;
}

.text-to-right {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .post-width-for-phone {
    width: 100%;
  }

  .sort-list-width-for-phone {
    width: 100%;
  }

  .center-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>