<script setup>
import MainMenu from "@/view/Menu.vue";
import Dropdown from "@/components/UI/Dropdown.vue";
import {computed, onMounted, ref} from "vue";
import axios from "axios";
import ModalComponent from "@/components/UI/ModalComponent.vue";
import ImageModalComponent from "@/components/ImageModalComponent.vue";

const groups = ref(new Map());

const isAddModelOpen = ref(false);

const isImgModelOpen = ref(false);

const imgModel = ref([]);

const inputYachtId = ref(null);

const loadGroups = async () => {
  try {
    const response = await axios.get(`https://api.knotzone.com/api/v1/yacht/groups/get/all`);
    groups.value = response.data.groups;
  } catch (error) {
    console.error('Error loading groups:', error);
  }
};

const closeImgModal = () => {
  isImgModelOpen.value = false;
};

const openImgModal = (img) => {
  imgModel.value = img
  isImgModelOpen.value = true;
};


const closeModal = () => {
  isAddModelOpen.value = false;
};

const openModal = () => {
  isAddModelOpen.value = true;
};

const removeYachtFromGroup = async (id) => {
  try {
    const data = {
      childId: id
    }
    await axios.post(`https://api.knotzone.com/api/v1/yacht/remove/yacht/from/group`, data);
  } catch (error) {
    console.error('Error remove yacht from group:', error);
  }
  await loadGroups();
};

const addNewAdToGroup = async (groupId) => {
  try {
    const data = {
      groupId: groupId,
      yachtId: inputYachtId.value
    }
    await axios.post(`https://api.knotzone.com/api/v1/yacht/add/new/add/to/group`, data);
  } catch (error) {
    console.error('Error add yacht to group:', error);
  }
  closeModal();
  await loadGroups();
};

const removeGroup = async (groupId) => {
  try {
    const data = {
      groupId: groupId
    }
    await axios.post(`https://api.knotzone.com/api/v1/yacht/remove/group`, data);
  } catch (error) {
    console.error('Error remove group:', error);
  }
  closeModal();
  await loadGroups();
};

const currentPage = ref(1);
const itemsPerPage = ref(15);

const totalItems = computed(() => groups.value.length);
const totalPages = computed(() => Math.ceil(totalItems.value / itemsPerPage.value));

const paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  // Преобразование значений Map в массив и их пагинация
  const itemsArray = Array.from(groups.value.values());
  return itemsArray.slice(startIndex, startIndex + itemsPerPage.value);
});

onMounted(() => {
  loadGroups();
});

</script>

<template>
  <div class="row">
    <div class="col-3">
      <MainMenu />
    </div>

    <div class="col-8">
      <Dropdown v-for="parent in paginatedItems" :key="parent.id" class="w-full bg-white rounded-lg shadow-lg mb-1">
        <template #header>
          <div class="flex w-full items-center justify-between border-b border-gray-300 py-2">
            <div class="flex items-center space-x-2 w-full">
              <img :src="parent.images[0]" class="w-12 h-12 rounded-full" alt="yacht image"
                   @click="openImgModal(parent.images)" />
              <div class="text-ellipsis overflow-hidden whitespace-nowrap text-sm">
                <a :href="`https://knotzone.com/yachts/${parent.id}`" target="_blank">
                  {{ parent.make }} {{ parent.name }} | ID: {{ parent.id }}
                </a>
              </div>
            </div>
          </div>
        </template>

        <template #button>
          <div class="bg-blue-300 hover:bg-blue-500 text-white p-1 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </div>
        </template>

        <template #content>
          <div class="mr-5 ml-5">
            <div v-for="child in parent.childs" :key="child.id" class="flex items-center justify-between py-3">
              <img :src="child.images[0]" class="w-12 h-12 rounded-lg" alt="yacht image"
                   @click="openImgModal(child.images)" />
              <div class="flex-1 ml-4 mr-2">
                <a :href="`https://knotzone.com/yachts/${child.id}`" target="_blank">
                  <div class="text-ellipsis overflow-hidden whitespace-nowrap">
                    {{ child.make }} {{ child.name }}
                  </div>
                </a>
              </div>
              <button
                  class="flex-none bg-red-300 hover:bg-red-500 text-white p-2 rounded"
                  title="Delete this ad from the group"
                  @click.prevent="removeYachtFromGroup(child.id)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="size-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
          <div class="w-full text-center">
            <button
                class="bg-green-300 hover:bg-green-500 text-white p-1 rounded mr-3"
                title="Add new ad to group"
                @click="openModal"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
              </svg>
            </button>
            <ModalComponent :is-open="isAddModelOpen"
                            @modal-close="closeModal"
                            name="Add new ad to group">
              <template #header>
                <div class="text-center w-full">
                  <h3>Add new ad to group</h3>
                </div>
              </template>
              <template #content>
                <form @submit.prevent="addNewAdToGroup(parent.id)">
                  <div>
                    <input v-model="inputYachtId" type="number" id="group_name"
                           class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           placeholder="id's ad" required />
                  </div>
                  <button
                      class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="submit">
                    Submit
                  </button>
                </form>
              </template>
              <template #footer>
              </template>
            </ModalComponent>
            <button
                class="bg-red-300 hover:bg-red-500 text-white p-1 rounded"
                title="Remove this group"
                @click.prevent="removeGroup(parent.id)"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                   stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </template>
      </Dropdown>
      <div v-if="totalPages > 1" class="flex justify-center space-x-2 mt-4">
        <button
            v-if="currentPage > 1"
            @click="currentPage--"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Previous
        </button>

        <button
            v-for="page in totalPages" :key="page"
            @click="currentPage = page"
            :class="{'bg-blue-700': currentPage === page, 'bg-blue-500': currentPage !== page}"
            class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          {{ page }}
        </button>

        <button
            v-if="currentPage < totalPages"
            @click="currentPage++"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Next
        </button>
      </div>
    </div>
  </div>
  <ImageModalComponent :is-open="isImgModelOpen"
                       :images="imgModel"
                  @modal-close="closeImgModal"/>
</template>

<style scoped>
</style>