<template>
  <main class="form-signin w-100 m-auto">
    <img class="mb-4" src="@/assets/img/empty-avatar.png" alt="our logo" width="290" height="200">
    <form @submit.prevent="submit" v-if="currentUser" class="mb-5">
      <h1 class="h3 mb-3 fw-normal" style="text-align: center">Сhange data</h1>
      <div class="form-floating">
        <input v-model="currentUser.firstName" class="form-control mb-3" placeholder="First Name">
        <label>First name</label>
      </div>
      <div class="form-floating">
        <input v-model="currentUser.lastName" class="form-control mb-3" placeholder="Last Name">
        <label>Last name</label>
      </div>
      <div class="form-floating">
        <input v-model="currentUser.email" type="email" class="form-control mb-3" placeholder="name@example.com">
        <label>Email address</label>
      </div>
      <div class="form-floating mb-3">
        <b-form-select id="type"
                       class="form-select"
                       :options="roleOptions"
                       v-model="currentUser.role" />
        <label>Role</label>
      </div>
      <button class="btn btn-primary w-100 py-2" type="submit">Submit</button>
    </form>

    <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert">
      {{ notify.message }}
    </div>
    <form @submit.prevent="changePass">
      <h1 class="h3 mb-3 fw-normal">Change password</h1>

      <div class="form-floating">
        <input v-model="password"
               type="password"
               class="form-control"
               id="password"
               placeholder="Password">
        <label for="password">Password</label>
      </div>

      <div class="form-floating">
        <input v-model="confirmPassword"
               type="password"
               class="form-control"
               id="confirmPassword"
               placeholder="Confirm password">
        <label for="confirmPassword">Confirm password</label>
      </div>

      <button class="mt-3 btn btn-primary w-100 py-2" type="submit">Change</button>
    </form>
  </main>
</template>

<script>
import {defineComponent} from 'vue'
import AdminService from "@/service/AdminService";
import adminService from "@/service/AdminService";

export default defineComponent({
  name: "UserForm",
  data() {
    return {
      currentUser: null,
      password: null,
      confirmPassword: null,
      notify: {
        cls: '',
        message: ''
      },
      roleOptions: [
        {text: 'ADMIN', value: 'ADMIN'},
        {text: 'USER', value: 'USER'},
        {text: 'MODERATOR', value: 'MODERATOR'}
      ],
    }
  },
  methods: {
    initUser(id) {
      AdminService.getUserById(id)
          .then(response => {
            this.currentUser = response.data;
          })
          .catch(e => {
            console.log(e);
          })
    },
    changePass() {
      adminService.changePassword({
            id: this.currentUser.id,
            pass: this.password,
            passConfirm: this.confirmPassword
          })
          .then(response => {
            this.password = null;
            this.confirmPassword = null;
            this.notify.message = response.data.message;
            console.log('change pass is complete')
          })
          .catch(e => {
            console.log(e)
          })
      console.log("change")
    },
    submit() {
      adminService.changeData(this.currentUser)
          .then(response => {
            this.currentUser = response.data;
            console.log('change data is complete')
          })
          .catch(e => {
            console.log(e)
          })
    }
  },
  mounted() {
    this.initUser(this.$route.params.id);
  },
})
</script>

<style scoped>
html,
body {
  height: 100%;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
  text-align: center;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>