<template>
  <div class="row feature">
    <div class="col-md-3">
      <MainMenu/>
    </div>
    <div class="col-md-8">



      <b-card-group v-if="items.length">
        <div class="col-2 mb-2" v-for="(user, index) in items" :key="index">
          <router-link :to="'/users/' + user.id" class="router">
            <b-card tag="article" class="mb-2 select-card single-card">
              <b-card-img :src="getAvatarImg" class="img-class"/>
              <b-card-title>
                {{ user.first_name }}
              </b-card-title>
              <b-card-text>
                {{ user.last_name }}
              </b-card-text>
              <b-card-footer>
                {{ user.role }}
              </b-card-footer>
            </b-card>
          </router-link>
        </div>

        <b-pagination
            v-if="countOfPage > 1"
            v-model="currentPage"
            :total-rows="countOfPage"
            :per-page="1"
            align="center"
            @click="changePage"
            first-number
            last-number
        ></b-pagination>
      </b-card-group>



    </div>
    <div class="col-md-1"/>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import MainMenu from "@/view/Menu.vue";
import AdminService from "@/service/AdminService";

export default defineComponent({
  name: "UsersPage",
  components: {MainMenu},
  props: {
    countOfPage: Number,
    filterProp: null
  },
  data() {
    return {
      isEmpty: false,
      currentPage: 1, // Текущая страница
      filter: null,
      items: [],
      searchName: null
    };
  },
  methods: {
    initUsers() {
      AdminService.getAllUsers()
          .then(response => {
            this.items = response.data.users;
          })
          .catch(e => {
            console.log(e);
          })
    }
  },
  mounted() {
    this.initUsers();
  },
  computed: {
    getAvatarImg() {
      return require(`@/assets/img/empty-avatar.png`);
    }
  }
})
</script>

<style scoped>
.img-class {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>