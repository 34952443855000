import {createStore} from 'vuex'

export default createStore({
    state: () => ({
        auth: false,
        message: ''
    }),
    getters: {},
    mutations: {
        setAuth: (state, auth) => {
            state.auth = auth;
        },
        setMessage: (state, message) => {
            state.message = message;
        }
    },
    actions: {
        setAuth: (context, auth) => {
            context.commit('setAuth', auth);
        },
        setMessage: (context, message) => {
            context.commit('setMessage', message);
        }
    },
    modules: {}
});
