<script setup>
    import {ref} from "vue";

    let showMoreFilters = ref(false);

    const toggleFilters = () => {
      showMoreFilters.value = !showMoreFilters.value;
    }
</script>

<template>
  <div>
    <div class="flex items-center">
      <div class="flex-1">
        <slot name="header">
          <!-- Здесь будет размещён контент для слота header -->
        </slot>
      </div>
      <div>
        <button @click="toggleFilters" class="p-2 rounded">
          <slot name="button">
            <!-- Здесь будет размещён контент для слота button -->
          </slot>
        </button>
      </div>
    </div>
    <div v-if="showMoreFilters" class="mt-1">
      <slot name="content">

      </slot>
    </div>
  </div>
</template>

<style scoped>

</style>