import http from "../http-common";

class YachtService {

    getCountOfPage(filter) {
        const queryFiltersParams = new URLSearchParams(filter).toString();
        return http.get(`/yachts/page/count/${queryFiltersParams}`);
    }

    getAllByPageNumber(sortType, page) {
        return http.get(`admin/ad/hiding/${sortType}/${page}`);
    }

    get(id) {
        return http.get(`/yacht/${id}`);
    }

    getAllCountries() {
        return http.get('/yachts/all/countries');
    }

    update(id) {
        return http.get(`/yachts/update/yacht/by/id/${id}`);
    }

    merge(ids) {
        return http.post(`/admin/merge/yachts/by/ids`, ids);
    }

}

export default new YachtService();
