<template>
  <div class="carousel" :style="{ width: containerWidth + '%', height: containerHeight + '%' }">
    <div class="carousel-inner" v-if="images.length">
      <div
          v-for="(image, index) in images"
          :key="index"
          :class="['carousel-item', { active: index === activeIndex }]"
      >
        <div class="background-image" :style="{ backgroundImage: `url(${image})` }"></div>
        <img :src="image" alt="Slide" class="overlay-image" />
      </div>
    </div>
    <template v-if="images.length !== 1">
      <a class="carousel-control-prev carousel-control"
         href="#"
         role="button"
         @click.prevent="prevSlide">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      </a>
      <a class="carousel-control-next carousel-control"
         href="#"
         role="button"
         @click.prevent="nextSlide">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
      </a>
    </template>
    <div class="empty-img" v-if="!images.length">
      <div class="background-image" :style="{ backgroundImage: `url(${getEmptyImg})` }"></div>
      <img :src="getEmptyImg" alt="Slide" class="overlay-image" />
    </div>
  </div>
</template>

<script>

export default {
  name: "marine-carousel",
  props: {
    images: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeIndex: 0,      // Индекс активной картинки
      containerWidth: 100, // Ширина контейнера Carousel
      containerHeight: 100 // Высота контейнера Carousel
    };
  },
  methods: {
    prevSlide() {
      this.activeIndex = (this.activeIndex - 1 + this.images.length) % this.images.length;
    },
    nextSlide() {
      this.activeIndex = (this.activeIndex + 1) % this.images.length;
    },
    getImage(imageByte) {
      return `data:image/jpg;base64,${imageByte}`;
    }
  },
  computed: {
    getEmptyImg() {
      return require(`@/assets/img/img-missing.jpg`);
    }
  }
};
</script>

<style scoped>
.carousel {
  position: relative;
  overflow: hidden;
//border: 5px dotted black;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  z-index: -1;
}

.overlay-image {
  position: relative;
  z-index: 1;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-inner {
  position: relative;
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.carousel-control {
  height: 100%;
}

.empty-img img {
  object-fit: contain; /* Уменьшение картинки с сохранением пропорций */
  width: 100%;
  height: 500px;
}

.carousel-control:hover {
  background-color: rgba(110, 127, 128, 0.3);
}

.carousel-item {
  transition: transform 0.3s ease;
}

.carousel-item img {
  object-fit: contain; /* Уменьшение картинки с сохранением пропорций */
  width: 100%;
  height: 500px;
}

.fill-image {
  object-fit: fill;
  background-color: rgb(248, 249, 250);
}

.carousel-item.next {
  transform: translateX(100%);
}

.carousel-item.prev {
  transform: translateX(-100%);
}

</style>