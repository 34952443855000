<script setup>
import {defineProps, defineEmits, ref} from "vue";
import {onClickOutside} from '@vueuse/core'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  images: {
    type: Array,
    required: true
  }
});

const emit = defineEmits(["modal-close", "submit"]);

const target = ref(null);

onClickOutside(target, () => emit('modal-close'));

const onClick = () => {
  activeIndex.value = 0;
  emit('modal-close');
}

const activeIndex = ref(0);

const prevSlide = () => {
  if (0 < activeIndex.value) {
    activeIndex.value = activeIndex.value - 1;
  } else {
    activeIndex.value = props.images.length - 1;
  }
}

const nextSlide = () => {
  if (activeIndex.value < props.images.length - 1) {
    activeIndex.value = activeIndex.value + 1;
  } else {
    activeIndex.value = 0;
  }
}

</script>

<template>
  <div v-if="props.isOpen" class="modal-mask">
    <div class="modal-wrapper">
      <template v-if="images.length !== 1">
        <div class="z-50">
          <a class="carousel-control-prev carousel-control"
             href="#"
             role="button"
             @click.prevent="prevSlide">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next carousel-control"
             href="#"
             role="button"
             @click.prevent="nextSlide">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </a>
        </div>
      </template>
      <div class="carousel" :style="{ width: 100 + '%', height: 100 + '%' }">
        <div class="carousel-inner" v-if="images.length">
          <div
              v-for="(image, index) in images"
              :key="index"
              :class="['carousel-item', { active: index === activeIndex }]"
          >
            <button
                class="absolute top-0 right-0 m-2 bg-gray-600 bg-opacity-60 hover:bg-opacity-90 text-white p-2 rounded z-50"
                @click.prevent="onClick">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                   class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div class="background-image" :style="{ backgroundImage: `url(${image})` }"></div>
            <img :src="image" alt="Slide" class="overlay-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.carousel-item img {
  object-fit: contain; /* Уменьшение картинки с сохранением пропорций */
  width: 100%;
  max-height: 90vh;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex; /* Используйте flex для центрирования модального окна */
  align-items: center; /* Вертикальное центрирование */
  justify-content: center; /* Горизонтальное центрирование */
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Показывать скроллбар при необходимости */
  padding: 20px 30px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 2px;
  display: inline-block; /* Это позволяет модальному окну быть размером с его содержимое */
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  height: 100%;
  width: 8vw;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.carousel-control:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.carousel {
  position: relative;
  overflow: hidden;
//border: 5px dotted black;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  z-index: -1;
}

.overlay-image {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-control {
  height: 100%;
}

</style>
