<template>
  <div id="app">
    <div class="body-container">
      <!-- Ваш код компонентов здесь -->
      <HeaderNavbar />
      <div class="content-container">
        <!-- Код остальных компонентов и содержимого страницы -->
        <router-view />
      </div>
      <FooterNavbar />
    </div>
  </div>
</template>

<script>

import HeaderNavbar from "@/view/static/HeaderNavbar.vue";
import FooterNavbar from "@/view/static/FooterNavbar.vue";

export default {
  name: 'App',
  components: {
    HeaderNavbar,
    FooterNavbar
  }
}
</script>

<style>
  #app {
    display: flex; /* Используем Flexbox */
    flex-direction: column; /* Элементы выстраиваются вертикально */
    height: 100%; /* Высота контейнера тянется на всю доступную высоту */
  }
  .body-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .content-container {
    flex: 1;
    padding: 20px;
  }
</style>
