<script setup>
import axios from "axios";
import {onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import ImageModalComponent from "@/components/ImageModalComponent.vue";
import Dropdown from "@/components/UI/Dropdown.vue";
import ChildYachts from "@/view/Catalog/ChildYachts.vue";
import ModalComponent from "@/components/UI/ModalComponent.vue";

const route = useRoute();

const yacht = ref({});

const isImgModelOpen = ref(false);

const modalIndex = ref(0);

const childYachts = ref([]);

const isModalAddImage = ref(false);

const inputImageUrl = ref(null);

const ids = ref([]);

const id = ref();

const isModalAddYachts = ref(false);

const notify = ref({
  cls: '',
  message: ''
});

const removeItem = (index) => {
  ids.value.splice(index, 1);
};

const addItem = () => {
  if (id.value != null
      && id.value > 0
      && !ids.value.includes(id.value)) {
    ids.value.push(id.value);
    id.value = '';
  } else {
    id.value = '';
  }
};

const submitAddYachts = async () => {

  const data = {
    yachtCatalogId: yacht.value.id,
    yachtIds: ids.value
  };

  await axios.post('https://api.knotzone.com/api/v1/catalog/add/yachts/to/catalog', data)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });

  await loadCatalogYacht();
  ids.value = [];
  isModalAddImage.value = false;
};

const openModalAddYachtsToCatalog = () => {
  isModalAddYachts.value = true;
};

const closeModalAddYachtsToCatalog = () => {
  isModalAddYachts.value = false;
};

const openImgModel = (index) => {
  modalIndex.value = index;
  isImgModelOpen.value = true;
}

const openModalAddImageToCatalog = () => {
  isModalAddImage.value = true;
};

const closeModalAddImageToCatalog = () => {
  isModalAddImage.value = false;
};

const closeImgModal = () => {
  isImgModelOpen.value = false;
}

const file = ref('');

const submitFile = async () => {
  let formData = new FormData();
  formData.append('file', file.value);
  // Использование шаблонной строки для URL
  axios.post(`https://api.knotzone.com/api/v1/catalog/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function () {
    console.log('SUCCESS!!');
  }).catch(function () {
    console.log('FAILURE!!');
  });
}

const handleFileUpload = () => {
  file.value = file.value.files[0];
}

const loadCatalogYacht = async () => {
  try {
    const id = route.params.id;
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/by/${id}`);
    yacht.value = response.data.yacht;
    childYachts.value = response.data.yachts;
  } catch (error) {
    console.error('Error loading groups:', error);
  }
};

const submitHandler = async () => {

  const data = {
    yachtCatalogId: yacht.value.id,
    imgUrl: inputImageUrl.value
  };

  await axios.post('https://api.knotzone.com/api/v1/catalog/add/img/to/catalog', data)
      .then(response => {
        console.log(response.data);
      })
      .catch(error => {
        console.error(error);
      });

  await loadCatalogYacht();
  closeModalAddImageToCatalog();
};

onMounted(() => {
  loadCatalogYacht();
});

</script>

<template>
  <div class="max-w-4xl mx-auto p-5 bg-white rounded-lg shadow-lg">
    <div class="text-center mb-6">
      <h1 class="text-2xl font-bold">{{ yacht.name }}</h1>
      <p v-if="yacht.designer" class="text-sm text-gray-500">Designed by: {{ yacht.designer }}</p>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div>
        <h2 class="text-lg font-semibold">Specifications</h2>
        <ul class="list-disc pl-5">
          <li v-if="yacht.ballastType">Ballast type: {{ yacht.ballastType }}</li>
          <li v-if="yacht.ballastKg !== 0 && yacht.ballastLb !== 0">Ballast: {{ yacht.ballastKg }} kg
            ({{ yacht.ballastLb }} lb)
          </li>
          <li v-if="yacht.beamFt !== 0 && yacht.beamM !== 0">Beam: {{ yacht.beamFt }} ft ({{ yacht.beamM }} m)</li>
          <li v-if="yacht.displacementKg !== 0 && yacht.displacementLb !== 0">Displacement: {{ yacht.displacementKg }}
            kg ({{ yacht.displacementLb }} lb)
          </li>
          <li v-if="yacht.hullSpeed">Hull Speed: {{ yacht.hullSpeed }} knots</li>
          <li v-if="yacht.laoft !== 0 && yacht.laom !== 0">LOA: {{ yacht.laoft }} ft ({{ yacht.laom }} m)</li>
          <li v-if="yacht.lwlft !== 0 && yacht.lwlm !== 0">LWL: {{ yacht.lwlft }} ft ({{ yacht.lwlm }} m)</li>
          <li v-if="yacht.maxDraftFt !== 0 && yacht.maxDraftM !== 0">Max Draft: {{ yacht.maxDraftFt }} ft
            ({{ yacht.maxDraftM }} m)
          </li>
          <li v-if="yacht.riggingType">Rigging Type: {{ yacht.riggingType }}</li>
          <li v-if="yacht.hullType">Hull Type: {{ yacht.hullType }}</li>
          <li v-if="yacht.comfortRatio != 0">Comfort Ratio: {{ yacht.comfortRatio }}</li>
        </ul>
      </div>
      <div>
        <h2 class="text-lg font-semibold">Dimensions</h2>
        <ul class="list-disc pl-5">
          <li v-if="yacht.ift !== 0 && yacht.im !== 0">Fore Triangle: {{ yacht.ift }} ft ({{ yacht.im }} m)</li>
          <li v-if="yacht.forestayLengthFT !== 0 && yacht.forestayLengthM !== 0">>Forestay Length:
            {{ yacht.forestayLengthFT }} ft ({{ yacht.forestayLengthM }} m)
          </li>
          <li v-if="yacht.jft !== 0 && yacht.jm !== 0">>J Measurement: {{ yacht.jft }} ft ({{ yacht.jm }} m)</li>
          <li v-if="yacht.pft !== 0 && yacht.pm !== 0">P Measurement: {{ yacht.pft }} ft ({{ yacht.pm }} m)</li>
          <li v-if="yacht.eft !== 0 && yacht.em !== 0">E Measurement: {{ yacht.eft }} ft ({{ yacht.em }} m)</li>
          <li>First Built: {{ yacht.firstBuilt }}</li>
        </ul>
      </div>
    </div>
    <div class="mt-4">
      <h2 class="text-lg font-semibold">Images</h2>
      <div class="flex space-x-4">
        <img v-for="(image, index) in yacht.images" :key="index" :src="image" alt="Yacht Image"
             class="w-32 h-32 rounded"
             @click="openImgModel(index)" />
        <ImageModalComponent :images="yacht.images"
                             :is-open="isImgModelOpen"
                             :index="modalIndex"
                             @modal-close="closeImgModal" />
      </div>
    </div>
    <!--  Выпадающий список яхт  -->

    <div class="text-center mb-6 mt-6">
      <Dropdown :title="`Yachts currently for sale`">

        <template #header>
          <div class="flex w-full items-center text-center justify-between border-b border-gray-300 py-2">
            <h1 class="text-2xl font-bold">
              View options for purchasing this yacht | Number of yachts: {{ childYachts.length }}
            </h1>
          </div>
        </template>
        <template #button>
          <div class="bg-blue-300 hover:bg-blue-500 text-white p-1 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                    d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0ZM3.75 12h.007v.008H3.75V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm-.375 5.25h.007v.008H3.75v-.008Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </div>
        </template>
        <template #content>
          <ChildYachts :yachts="childYachts" />
        </template>
      </Dropdown>
    </div>

    <!--    -->
    <div class="text-center mb-6 mt-6">
      <h1 class="text-2xl font-bold">Admin edit</h1>
      <button class="w-1/5 rounded-full border-2 bg-blue-200 hover:bg-blue-300"
              @click="openModalAddImageToCatalog">
        <h5> Add new image </h5>
      </button>
      <button class="w-1/5 rounded-full border-2 bg-blue-200 hover:bg-blue-300"
              @click="openModalAddYachtsToCatalog">
        <h5> Add new yachts </h5>
      </button>
      <router-link :to="`/catalog/edit/yacht/`+ yacht.id">
        <button class="w-1/5 rounded-full border-2 bg-blue-200 hover:bg-blue-300">
          <h5> Edit catalog </h5>
        </button>
      </router-link>
    </div>

    <!--  auto group  -->

    <div class="mt-10 flex justify-center items-center">
      <div class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md">
        <div class="flex flex-col space-y-4">
          <label class="block">
            <span class="sr-only">Choose file</span>
            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()"
                   class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus:border-blue-500 p-2.5" />
          </label>
          <button @click="submitFile()"
                  class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            Submit
          </button>
        </div>
      </div>
    </div>

  </div>
  <ModalComponent :is-open="isModalAddYachts"
                  @modal-close="closeModalAddYachtsToCatalog"
                  @submit.prevent="submitAddYachts"
                  name="first-modal">
    <template #header>
      <div class="text-center w-full">
        <h3>Add new yachts to catalog's yacht</h3>
      </div>
    </template>
    <template #content>
      <div class="col-md-8 text-center">
        <form @submit.prevent="addItem">
          <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert" class="mt-2">
            {{ notify.message }}
          </div>
          <div class="container mt-5">
            <div class="input-group mb-3">
              <input
                  type="number"
                  class="form-control"
                  placeholder="Enter id"
                  v-model="id"
              />
              <button class="btn btn-outline-secondary" type="submit">+</button>
            </div>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                  v-for="(item, index) in ids"
                  :key="index">
                {{ item }}
                <button class="btn btn-danger btn-sm" type="button" @click="removeItem(index)">
                  -
                </button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </template>
    <template #footer>
      <button v-if="ids.length" class="mt-3 btn btn-primary w-100 py-2" type="button" @click.prevent="submitAddYachts">
        Add yachts
      </button>
    </template>
  </ModalComponent>
  <ModalComponent :is-open="isModalAddImage"
                  @modal-close="closeModalAddImageToCatalog"
                  @submit.prevent="submitHandler"
                  name="first-modal">
    <template #header>
      <div class="text-center w-full">
        <h3>Add new image to catalog's yacht</h3>
      </div>
    </template>
    <template #content>
      <form @submit.prevent="submitHandler" class="text-center">
        <div>
          <input v-model="inputImageUrl" type="text" id="group_name"
                 class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                 placeholder="Image url" required />
        </div>
        <button
            class="mt-2 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            type="submit">
          Submit
        </button>
      </form>
    </template>
    <template #footer>
    </template>
  </ModalComponent>
</template>

<style scoped>

</style>