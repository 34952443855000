import http from "../http-common";

class YachtService {

    getAllScrapedWebsites() {
        return http.get(`/admin/sites/list`);
    }

    getAllUsers() {
        return http.get(`/admin/users/list`);
    }

    getUserById(id) {
        return http.get(`/admin/user/${id}`);
    }

    updateAndGetAllScrapedWebsites() {
        return http.get(`/admin/sites/update/information`);
    }

    changeData(data) {
        return http.post(`/admin/user/change/data`, data)
    }

    changePassword(data) {
        return http.post(`/admin/user/change/password`, data)
    }

    checkForNewPosts(data) {
        return http.post(`/parse/check/for/new/posts`, data);
    }

}

export default new YachtService();
