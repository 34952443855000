import axios from "axios";
// dev
axios.defaults.baseURL = 'https://api.knotzone.com/api/v1';
// prod :
// axios.defaults.baseURL = 'https://api.knotzone.com/api/v1';

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;
        const response = await axios.post('/auth/refresh', {}, {withCredentials: true});

        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

            return axios(error.config);
        }
    }
    refresh = false;
    return error;
});