<template>
  <main class="form-signin w-100 h-100 m-auto">
    <img class="mb-4" src="../assets/img/logo/our-logo.png" alt="our logo" width="290" height="200" >
    <div  v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert">
      {{ notify.message }}
    </div>
    <form @submit.prevent="submit">
      <h3 class="h3 mb-3 fw-normal">Please sign in</h3>
      <div class="form-floating">
        <input v-model="loginData.email" type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
        <label for="floatingInput">Email address</label>
      </div>
      <div class="form-floating">
        <input v-model="loginData.password" type="password" class="form-control" id="floatingPassword" placeholder="Password">
        <label for="floatingPassword">Password</label>
      </div>

      <div class="mb-3">
        <h6>Forgot your password? In vain!</h6>
      </div>

      <button class="btn btn-primary w-100 py-2" type="submit">Sign in</button>
    </form>

  </main>
</template>

<script>
import {defineComponent, reactive} from "vue";
  import {useRouter} from "vue-router";
  import axios from "axios";
  import {useStore} from "vuex";

  export default defineComponent({
    name: "LoginForm",
    setup() {
      const notify = reactive( {
        cls: '',
        message: 'Invalid email address or password'
      })

      const loginData = reactive({
        email: '',
        password: ''
      });

      const router = useRouter();
      const store = useStore();

      const submit = async () => {
        let response;
        try {
          response = await axios.post('/auth/adminLogin', loginData, {
            withCredentials: true
          });

          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

          await store.dispatch('setAuth', true);
          await router.push('/')
        } catch (e) {
          if (response.response.status === 503) {
            notify.cls = 'danger';
            notify.message = 'Service unavailable!';
          } else {
            notify.cls = 'danger';
            notify.message = 'Email does not exist!';
          }
        }
        /*try {
          const response = await axios.post('/auth/login', loginData, {
            withCredentials: true
          });

          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;

          await router.push('/')
        } catch (e) {
          notify.cls = 'danger';
        }*/
      }

      return {
        loginData,
        submit,
        notify
      }
    }
  });
</script>

<style scoped>
  html,
  body {
    height: 100%;
  }

  .form-signin {
    text-align: center;
    max-width: 330px;
    padding: 1rem;
    margin-top: 10% !important;
  }

  .form-signin .form-floating:focus-within {
    z-index: 2;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
</style>