<script setup>
import MainMenu from "@/view/Menu.vue";
import axios from "axios";
import {ref} from "vue";

const file = ref('');

const submitFile = async () => {
  let formData = new FormData();
  formData.append('file', file.value);
  // Использование шаблонной строки для URL
  axios.post(`https://api.knotzone.com/api/v1/yacht/upload-file`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(function () {
    console.log('SUCCESS!!');
  }).catch(function () {
    console.log('FAILURE!!');
  });
}

const handleFileUpload = () => {
  file.value = file.value.files[0];
}

</script>

<template>
  <div class="row">
    <div class="col-3">
      <MainMenu />
    </div>

    <div class="col-8">
      <div class="mt-10 flex justify-center items-center">
        <div class="p-6 max-w-sm bg-white rounded-lg border border-gray-200 shadow-md">
          <div class="flex flex-col space-y-4">
            <label class="block">
              <span class="sr-only">Choose file</span>
              <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" class="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer focus:outline-none focus:border-blue-500 p-2.5" />
            </label>
            <button @click="submitFile()" class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>