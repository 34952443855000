<template>
  <div class="row feature">
    <div class="col-md-3">
      <MainMenu />
    </div>
    <div class="col-md-8">

      <form @submit.prevent="addItem">
        <div v-if="notify.cls" :class="`alert alert-${notify.cls}`" role="alert" class="mt-2">
          {{ notify.message }}
        </div>
        <div class="container mt-5">
          <div class="input-group mb-3">
            <input
                type="number"
                class="form-control"
                placeholder="Enter id"
                v-model="ad"
                @keyup.enter="addItem"
            />
            <button class="btn btn-outline-secondary" type="submit">+</button>
          </div>
          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between align-items-center"
                v-for="(item, index) in ads"
                :key="index">
              {{ item }}
              <button class="btn btn-danger btn-sm" type="button" @click="removeItem(index)">
                -
              </button>
            </li>
          </ul>
        </div>
      </form>

      <button v-if="ads.length" class="mt-3 btn btn-primary w-100 py-2" type="button" @click.prevent="mergeAd">
        MERGE
      </button>

    </div>
    <div class="col-md-1" />
  </div>
</template>

<script>
import {defineComponent, reactive} from 'vue'
import MainMenu from "@/view/Menu.vue";
import YachtService from "@/service/YachtService";

export default defineComponent({
  name: "MergingAd",
  components: {MainMenu},
  data() {
    return {
      ad: null,
      ads: [],
      notify: reactive({
        cls: '',
        message: ''
      })
    }
  },
  methods: {
    addItem() {
      if (this.ad != null
          && this.ad > 0
          && !this.ads.includes(this.ad)) {
        this.ads.push(this.ad);
        this.ad = '';
      } else {
        this.ad = '';
      }
    },
    removeItem(index) {
      this.ads.splice(index, 1);
    },
    async mergeAd() {
      await YachtService.merge({ids: this.ads})
          .then(response => {
            if (response.status === 200) {
              this.notify.cls = 'success';
              this.notify.message = response.data.message;
            } else {
              this.notify.cls = 'danger';
              this.notify.message = response.data.message;
            }
          })
          .catch(error => {
            this.notify.cls = 'danger';
            if (error.response) {
              // Сервер вернул ошибку и ее описание
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
              this.notify.message = error.response.data.message || 'An error occurred while executing the request.';
            } else if (error.request) {
              // Запрос был сделан, но ответ не был получен
              console.log(error.request);
              this.notify.message = 'No response from server.';
            } else {
              // Произошла ошибка при настройке запроса
              console.log('Error', error.message);
              this.notify.message = 'Error setting up request.';
            }
          });
      this.ads = [];
      this.ad = null;
    }
  }
})
</script>

<style scoped>
.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-danger {
  margin-left: 10px;
}
</style>