<template>
  <nav class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0">
    <router-link to="/" class="navbar-brand col-sm-3 col-md-2 mr-0 m-lg-2">Knotzone ADMIN</router-link>
<!--    <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search">-->
    <h1 v-if="auth" style="color: white">{{ message }}</h1>
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a v-if="auth()" @click="logout" class="router nav-link">
          Logout
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import {useStore} from "vuex";
import {ref} from "vue";
import axios from "axios";
import {useRouter} from "vue-router";

export default ({
  name: "HeaderNavbar",
  methods: {
    auth() {
      const store = useStore();
      return store.state.auth;
    }
  },
  setup() {
    const message = ref('');
    const store = useStore();
    const router = useRouter();


    const logout = async () => {
      await axios.post("/auth/logout", {}, {withCredentials: true})

      axios.defaults.headers.common['Authorization'] = ``;

      await store.dispatch('setAuth', false);
      await router.push('/login');
    }

    return {
      logout,
      message
    }
  }
});
</script>

<style scoped>

</style>