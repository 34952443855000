<script setup>
import {onMounted, ref} from "vue";
import axios from "axios";
import {useRoute, useRouter} from "vue-router";

const catalog = ref([]);
const currentPage = ref(1);
const route = useRoute();
const router = useRouter();
const totalPages = ref();
const searchModel = ref();

const loadCatalog = async () => {
  try {
    let page = route.params.page;
    if (!page) {
      page = 1;
    }
    let searchName = route.params.searchName;
    if (!searchName) {
      searchName = 'none';
    } else {
      searchModel.value = searchName;
    }
    currentPage.value = page;
    const response = await axios.get(`https://api.knotzone.com/api/v1/catalog/get/all/by/${searchName}/page/${page}`);
    catalog.value = response.data.catalog;
    totalPages.value = response.data.numberOfPage;
  } catch (error) {
    console.error('Error loading catalog:', error);
  }
};

const nextPage = async () => {
  if (currentPage.value >= totalPages.value) {
    return;
  }
  const nextPage = Number(currentPage.value) + 1;
  if (searchModel.value !== '' && searchModel.value) {
    await router.push(`/catalog/q/${searchModel.value}/page/${nextPage}`);
  } else {
    await router.push(`/catalog/page/${nextPage}`);
  }
  await loadCatalog();
}

const prev = async () => {
  if (currentPage.value <= 1) {
    return;
  }
  const nextPage = Number(currentPage.value) - 1;
  if (searchModel.value !== '' && searchModel.value) {
    await router.push(`/catalog/q/${searchModel.value}/page/${nextPage}`);
  } else {
    await router.push(`/catalog/page/${nextPage}`);
  }

  await loadCatalog();
}

const performSearch = async () => {
  if (searchModel.value !== '' && searchModel.value) {
    await router.push(`/catalog/q/${searchModel.value}`);
  } else {
    await router.push(`/catalog`);
  }
  await loadCatalog();
}

onMounted(() => {
  loadCatalog();
});
</script>

<template>
  <div class="max-w-7xl mx-auto py-5">
    <h1 class="text-3xl font-bold text-center mb-6">Yacht's catalog</h1>
    <div class="flex justify-center items-center p-4">
      <input
          v-model="searchModel"
          @keyup.enter="performSearch"
          placeholder="Search model name"
          class="form-input px-4 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-gray-800 focus:border-transparent"
          type="text"
      />
      <button
          @click="performSearch"
          class="bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-r-md"
      >
        SEARCH
      </button>
    </div>
    <table class="min-w-full bg-white rounded-lg shadow-md">
      <thead class="bg-gray-800 text-white">
      <tr>
        <th class="py-3 px-6 text-xs font-semibold text-left">Image</th>
        <th class="py-3 px-6 text-xs font-semibold text-left">Model</th>
        <th class="py-3 px-6 text-xs font-semibold text-left">LAO (ft/m)</th>
        <th class="py-3 px-6 text-xs font-semibold text-left">First build</th>
      </tr>
      </thead>
      <tbody class="text-gray-700">
      <tr v-for="yacht in catalog" :key="yacht.id">
        <td class="py-3 px-6">
          <img :src="yacht.images[0]" alt="Image of Yacht" class="w-20 h-20 rounded-full">
        </td>
        <td class="py-3 px-6">
          <router-link class="router text-blue-600 border-b" :to="'/catalog/yacht/' + yacht.id">
            <h3 class="text-lg font-semibold">{{ yacht.name }}</h3>
          </router-link>
        </td>
        <td class="py-3 px-6">
          {{ yacht.laoft }} ft / {{ yacht.laom }} m
        </td>
        <td class="py-3 px-6">
          {{ yacht.firstBuilt }}
        </td>
      </tr>
      </tbody>
    </table>
    <div class="flex justify-between items-center mt-4">
      <button @click.prevent="prev" class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">Previous</button>
      <span>Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click.prevent="nextPage" class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">Next</button>
    </div>
  </div>
</template>


<style scoped>

</style>