<script setup>
import {defineProps, defineEmits, ref} from "vue";
import {onClickOutside} from '@vueuse/core'

const props = defineProps({
  isOpen: {
    type: Boolean
  }
});

const emit = defineEmits(["modal-close", "submit"]);

const target = ref(null);

onClickOutside(target, () => emit('modal-close'));
</script>

<template>
  <div v-if="props.isOpen" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container" ref="target">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer">
          <form >
            <slot name="footer"></slot>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex; /* Используйте flex для центрирования модального окна */
  align-items: center; /* Вертикальное центрирование */
  justify-content: center; /* Горизонтальное центрирование */
}

.modal-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  width: auto; /* Ширина по содержимому, но не более 90vw */
  height: auto; /* Высота по содержимому, но не более 90vh */
  margin: 150px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
}
</style>
