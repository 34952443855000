<template>
  <div class="row feature">
    <div class="col-md-3">
      <MainMenu/>
      <div>{{message}}</div>
    </div>
    <div class="col-md-8">
      <ParserPage/>
    </div>
    <div class="col-md-1"/>
  </div>
</template>

<script>
import {computed, defineComponent, onMounted, ref} from 'vue'
import MainMenu from "@/view/Menu.vue";
import {useStore} from "vuex";
import axios from "axios";
import ParserPage from "@/view/Parser.vue";
/*import {useRouter} from "vue-router";*/

export default defineComponent({
  name: "AdminPage",
  /*beforeRouteEnter(to, from, next) {
    const router = useRouter();
    const store = useStore();
    if (!store.state.auth) {
      router.push('/login');
    } else {
      next()
    }
  },*/
  components: {ParserPage, MainMenu},
  setup() {
    const message = ref('You are not logged in');
    const store = useStore();
    const auth = computed(() => store.state.auth);

    onMounted(async () => {
      try {
        const {data} = await axios.get('auth/user');

        message.value = `Hi ${data.first_name} ${data.last_name}`;

        await store.dispatch('setAuth', true);
      } catch (e) {
        await store.dispatch('setAuth', false);
      }
    });

    return {
      message,
      auth
    }
  }
})
</script>

<style scoped>
  .admin-menu {
    display: inline-block;
  }

  .parser-part {
    display: inline-block;
  }

</style>