<template>
  <div v-if="currentYacht" class="edit-form">
    <form>
      <div class="row feature">
        <div class="col-md-2">
        </div>
        <div class="col-md-6">
          <marine-carousel :images=currentYacht.images></marine-carousel>
        </div>
        <div class="col-md-4">
          <h1 class="featurette-heading fw-normal lh-1" id="name" style="font-weight: bold !important;">
            {{ currentYacht.make }} {{ currentYacht.name }} | {{ getCondition(currentYacht.isNew) }}
          </h1>
          <h2>
            {{ formatPrice(currentYacht.price) }} {{ currentYacht.currency }}
          </h2>
          <div class="mt-3">
            <small v-if="currentYacht.createdDate != null" class="text-muted">
              Create date: {{ getDatePattern(currentYacht.createdDate) }}
            </small>
            <br>
            <small v-if="currentYacht.modifiedDate != null" class="text-muted">
              Modified date: {{ getDatePattern(currentYacht.modifiedDate) }}
            </small>
            <br>
            <small v-if="currentYacht.lastUpdateDate != null" class="text-muted">
              Last check: {{ getTimeElapsed(currentYacht.lastUpdateDate) }}
            </small>
          </div>

          <div v-if="currentYacht.yachtOwner" class="accordion mt-3">
            <div class="mb-1">
              <b-card>
                <b-card-body>
                  <b-card-text style="text-align: center">
                    <p><b>Owner name:</b> {{ currentYacht.yachtOwner.name }}</p>
                    <p style="border-top: black solid 1px"><b>Locale:</b> {{ currentYacht.yachtOwner.locale }}</p>
                    <p style="border-top: black solid 1px"><b>Number</b></p>
                    <div v-for="(number, index) in currentYacht.yachtOwner.phoneNumbers"
                         :key="index">
                      <li><i>{{ number.type }}:</i> {{ number.number }}</li>
                    </div>
                  </b-card-text>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="form-group  mt-3">
                <label for="postLink">More info: </label>
                <a :href="currentYacht.postUrl" target="_blank" class="image-button" id="postLink">
                  <img :src="getLogo" :alt="currentYacht.eurlSite" :title="currentYacht.eurlSite" class="button-image">
                </a>
              </div>
            </div>
            <div class="col">
              <div class="form-group  mt-3">
                <button @click="updateYacht(currentYacht.id)" :disabled="isLoading" class="custom-button">
                  <span v-if="isLoading" class="spinner" />
                  <span v-else>
                    <i class="fas fa-sync-alt"></i> Update
                  </span>
                </button>
              </div>
            </div>
          </div>


        </div>
      </div>
      <div class="row featurette div-row">
        <div class="col-md-2"></div>
        <div class="col-md-10">
          <div>
            <b-tabs content-class="mt-3" justified>
              <b-tab title="Description" active>
                <div v-html="currentYacht.description" />
              </b-tab>
              <b-tab title="Characteristics">
                <div class="row">
                  <div class="col mb-4">
                    <p><b>Make:</b> {{ currentYacht.make }}</p>
                    <p><b>Model:</b> {{ currentYacht.model }}</p>
                    <p><b>Yacht type:</b> {{ currentYacht.yachtType }}</p>
                    <p><b>Yacht class:</b> {{ currentYacht.yachtClass }}</p>
                  </div>
                  <div class="col mb-4">
                    <p v-if="currentYacht.yachtDimensions.nominalLengthFT">
                      <b>Nominal length in feet:</b> {{ currentYacht.yachtDimensions.nominalLengthFT }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.nominalLengthM">
                      <b>Nominal length in meters:</b> {{ currentYacht.yachtDimensions.nominalLengthM }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.overallLengthFT">
                      <b>Overall length in feet:</b> {{ currentYacht.yachtDimensions.overallLengthFT }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.overallLengthM">
                      <b>Overall length in meters:</b> {{ currentYacht.yachtDimensions.overallLengthM }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.waterlineLengthFT">
                      <b>Waterline length in feet:</b> {{ currentYacht.yachtDimensions.waterlineLengthFT }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.waterlineLengthM">
                      <b>Waterline length in meters:</b> {{ currentYacht.yachtDimensions.waterlineLengthM }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.deckLengthFT">
                      <b>Deck length in meters:</b> {{ currentYacht.yachtDimensions.deckLengthFT }}
                    </p>
                    <p v-if="currentYacht.yachtDimensions.deckLengthM">
                      <b>Deck length in meters:</b> {{ currentYacht.yachtDimensions.deckLengthM }}
                    </p>
                  </div>
                  <div class="col mb-4" v-if="currentYacht.yachtSpeed">
                    <p v-if="currentYacht.yachtSpeed.maxKn">
                      <b>Max hull speed:</b> {{ currentYacht.yachtSpeed.maxKn }}
                    </p>
                    <p v-if="currentYacht.yachtSpeed.cruisingKn">
                      <b>Cruising speed:</b> {{ currentYacht.yachtSpeed.cruisingKn }}
                    </p>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Location">
                <div class="row">
                  <div class="col mb-4">
                  </div>
                  <div class="col mb-4">
                    <p><b>City:</b> {{ currentYacht.city }}</p>
                    <p><b>Country:</b> {{ currentYacht.country }}</p>
                    <p><b>Postal code:</b> {{ currentYacht.postalCode }}</p>
                  </div>
                  <div class="col mb-4">
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>

    </form>
  </div>
</template>

<script>

import YachtService from "@/service/YachtService";

export default {
  name: "marine-yacht",
  data() {
    return {
      currentYacht: null,
      message: '',
      items: [],
      isLoading: false
    };
  },
  methods: {
    getYacht(id) {
      YachtService.get(id)
          .then(response => {
            this.currentYacht = response.data;
          })
          .catch(e => {
            console.log(e);
          });
    },
    getDatePattern(date) {
      const selectedDate = new Date(date);
      const year = selectedDate.getFullYear();
      const month = String(selectedDate.getMonth() + 1).padStart(2, '0');
      const day = String(selectedDate.getDate()).padStart(2, '0');
      return `${year}.${month}.${day}`;
    },
    getImage(imageName) {
      return require(`@/../public/dbImg/${imageName}`);
    },
    formatPrice(value) {
      if (value == null) return 'price is not specified';
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getCondition(isNew) {
      if (isNew) {
        return 'NEW';
      } else {
        return 'USED';
      }
    },
    // calculate update time
    getTimeElapsed(date) {
      const currentTime = new Date();
      const timeDiffInSeconds = Math.floor((currentTime - new Date(date)) / 1000);

      if (timeDiffInSeconds < 60) {
        return 'Less than a minute ago';
      } else if (timeDiffInSeconds < 3600) {
        const minutes = Math.floor(timeDiffInSeconds / 60);
        return `${minutes} minutes ago`;
      } else if (timeDiffInSeconds < 3600 * 24) {
        const hours = Math.floor(timeDiffInSeconds / 3600);
        const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
        return `${hours} hours and ${minutes} minutes ago`;
      } else {
        const days = Math.floor(timeDiffInSeconds / (3600 * 24));
        if (days > 1) {
          return `${days} days ago`
        } else {
          return `${days} day ago`
        }
      }
    },
    updateYacht(id) {
      this.isLoading = true;
      YachtService.update(id)
          .then(response => {
            this.currentYacht = response.data;
          })
          .catch(e => {
            console.log(e);
          }).finally(() => this.isLoading = false)
    }
  },
  mounted() {
    this.message = '';
    this.getYacht(this.$route.params.id);
  },
  computed: {
    getLogo() {
      console.log(this.currentYacht.eurlSite)
      switch (this.currentYacht.eurlSite) {
        case "BOTENTEKOOP":
          return require(`@/assets/img/logo/botentekoop-logo.svg`);
        case "BOATSANDOUTBOARDS":
          return require(`@/assets/img/logo/boatsandoutboards-logo.svg`);
        case "ANNONCESBATEAU":
          return require(`@/assets/img/logo/annoncesbateau-logo.svg`);
        case "BOOT24":
          return require(`@/assets/img/logo/boot24-logo.svg`);
        default:
          return '';
      }
    }
  }
};
</script>

<style>
.edit-form {
  margin: auto;
}

.image-button {
  display: inline-block;
  width: 200px; /* Задайте нужную ширину кнопки */
  height: 50px; /* Задайте нужную высоту кнопки */
}

.button-image {
  margin-left: 10%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button-image:hover {
  margin-left: 10%;
  width: 105%;
  height: 105%;
  object-fit: contain;
}

.div-row {
  margin-top: 20px;
}

/* Стили для кнопки и спиннера */
.custom-button {
  position: relative;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
